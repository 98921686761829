import * as React from "react";
import CaseHeroImage from "./caseHeroImage";

const CaseImagesBlock = ({ images=[]}) => {

    return (
        <div className="case-images-block">
            {images.map((image, index) => (
                <CaseHeroImage key={index} image={image} imageALT={`Case image ${index}`}/>
            ))}
        </div>
    )
}

export default CaseImagesBlock;
