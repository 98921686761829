import * as React from "react";

const CaseImageSlide = ({ image, imageALT, imagePosition="left", title, text}) => {

    return (
        <div className="case-image-slide">
            {imagePosition === "left" && <img className="case-image-slide__image" src={image} alt={imageALT}/>}
            <div className="case-image-slide__text">
                {title && <h3 className="case-image-slide__text__title">{title}</h3>}
                <p className="case-image-slide__text__paragraph" dangerouslySetInnerHTML={{__html: text}}></p>
            </div>
            {imagePosition === "right" && <img className="case-image-slide__image" src={image} alt={imageALT}/>}
        </div>
    )
}

export default CaseImageSlide;
