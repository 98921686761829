import * as React from "react";
import { Helmet } from "react-helmet";
import { withPrefix, Link } from "gatsby";

import VideoBg from "../components/videoBg";

import { FRONT_DOMAIN } from "../config";

import "../scss/main.scss";
import 'swiper/css';
import PageHeader from "../components/pageHeader";
import PageSubHeader   from "../components/pageSubHeader";
import CaseTestimonials from "../components/caseTestimonials";
import CaseSwitcher from "../components/caseSwitcher";
import CaseHeroImage from "../components/caseHeroImage";
import MDQImage from "../img/cases/mqd/preview.png";
import MDQImage0 from "../img/cases/mqd/image_0.png";
import CaseImagesBlock from "../components/caseImagesBlock";
import MDQImage21 from "../img/cases/mqd/image_2_1.png";
import MDQImage22 from "../img/cases/mqd/image_2_2.png";
import MDQImage31 from "../img/cases/mqd/image_3_1.png";
import MDQImage32 from "../img/cases/mqd/image_3_2.png";
import MDQImage33 from "../img/cases/mqd/image_3_3.png";
import CaseImageSlide from "../components/caseImageSlide";
import MDQImage4 from "../img/cases/mqd/image_4.png";

const MDQCase = () => {

    return (
        <>
            <Helmet>
                <title>Mother’s Day Queen: The AI Magic Mirror | Works | Teleport</title>
                <script src={withPrefix('inobounce.js')}></script>
                <meta property="og:image" content={`${FRONT_DOMAIN}/img/share_default.jpg`}/>
                <link rel="preconnect" href="https://fonts.googleapis.com"/>
                <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin/>
                <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"
                      rel="stylesheet"/>
            </Helmet>

            <VideoBg/>

            <div className="site-section__about-top-background"></div>

            <section id="main-section" className="site-section">
                <PageHeader
                    annotationText={"@ Hong Kong MTR Stations"}
                    titleText={"Mother’s Day Queen: The AI Magic Mirror"}
                    subTitleText={"Discover “the queen” inside through the AI-powered interactive photo booths."}
                />
                <CaseTestimonials testimonials={['Artificial intelligence', 'Interactive experience']}/>
                <CaseHeroImage image={MDQImage0} imageALT="Mother’s Day Queen: The AI Magic Mirror"/>
                <PageSubHeader text="To celebrate Mother's Day, five AI magic mirrors were distributed across Hong Kong in collaboration with the Hong Kong MTR. "/>
                <CaseImagesBlock images={[MDQImage21, MDQImage22]}/>
                <PageSubHeader text="Guided by a talking Magic Mask Metahuman, the AI-powered system captured the user's likeness and generated a unique portrait of each woman as a Queen in real-time through a specially trained neural network and advanced algorithms. Every portrait was one of a kind, representing Western or Asian queens and celebrating the diversity and richness of royal heritage. This unique blend of technology and storytelling provided an enchanting and memorable experience."/>
                <CaseImagesBlock images={[MDQImage31, MDQImage32, MDQImage33]}/>
                <CaseImageSlide image={MDQImage4} title="Business objective:" text="The Mother’s Day Queen Magic Mirror displayed a more unique and memorable approach to traditional offline marketing campaigns. It successfully showcased a method to increase the customer’s engagement and experience towards the brand, as well as a powerful strategy to get more “viral” exposure with the ease of online sharability. <br><br>Participants could download their digital portraits via QR codes or receive printed postcards at the end of the journey, making this campaign more data-driven as we could measure engagement. "/>
                <CaseSwitcher previousLink="/works/bentley/" previousName="Bentley digital box" nextLink="/works/emotive-clouds-show/" nextName="The Emotive Clouds Show"/>
            </section>

        </>
    )
}

export default MDQCase;
