import * as React from "react";
import AngledButton  from "./angledButton";

const CaseSwitcherArrowToLeftImage = () => {
    return (
        <div>
            <svg width="25" height="33" viewBox="0 0 25 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.26673 16.3867L0.761343 9.88134L1.74514 8.89754L8.25053 15.4029L23.1481 0.505371L24.138 1.49532L13.4831 12.1502C11.14 14.4934 11.14 18.2924 13.4831 20.6355L24.3726 31.525L23.3888 32.5088L8.25667 17.3767L1.51061 24.1227L0.520662 23.1328L7.26673 16.3867Z"
                    fill="white"/>
            </svg>
        </div>
    )
}

const CaseSwitcherArrowToRightImage = () => {
    return (
        <svg width="24" height="33" viewBox="0 0 24 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.1058 16.3867L23.6112 9.88134L22.6274 8.89754L16.122 15.4029L1.22448 0.505371L0.234528 1.49532L10.8894 12.1502C13.2326 14.4934 13.2326 18.2924 10.8894 20.6355L0 31.525L0.983801 32.5088L16.1159 17.3767L22.8619 24.1227L23.8519 23.1328L17.1058 16.3867Z"
                fill="white"/>
        </svg>
    )
}

const CaseSwitcherItem = ({name, link, type = 'previous'}) => {

    function handleClick() {
        window.location = link;
    }

    return (
        <div className="case-switcher__item case-switcher__project-item" onClick={handleClick}>
            {type === 'previous' && <span className="case-switcher__item-title">Previous project</span>}
            {type === 'next' && <span className="case-switcher__item-title">Next project</span>}
            {name && <span className="case-switcher__item-name">{name}</span>}
            {type === 'previous' && <CaseSwitcherArrowToLeftImage/>}
            {type === 'next' && <CaseSwitcherArrowToRightImage/>}
        </div>
    )
}

const CaseSwitcher = ({ previousName, previousLink, nextName, nextLink=[] }) => {

    return (
        <>
            <div className="case-switcher">
                {previousName && previousLink &&
                    <CaseSwitcherItem name={previousName} link={previousLink} type='previous'/>}
                <div className="case-switcher__item case-switcher__middle-item">
                    <AngledButton buttonText="Contact us" buttonLink="/contact/"/>
                </div>
                {nextName && nextLink && <CaseSwitcherItem name={nextName} link={nextLink} type='next'/>}
            </div>
            <div className="case-switcher__mobile-button">
                <AngledButton buttonText="Contact us" buttonLink="/contact/"/>
            </div>
        </>
    )
}

export default CaseSwitcher;
