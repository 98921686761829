import * as React from "react";

const AngledButton = ({ buttonText, buttonLink }) => {

    return (
        <div className="angled-button">
            <a className="angled-button__button" href={buttonLink}>{buttonText}</a>
        </div>
    )
}

export default AngledButton;
