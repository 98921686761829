import React, { useEffect, useState,  } from "react";


const CaseHeroImage = ({ image, imageALT}) => {

    const containerElement = React.useRef(null);
    const imageElement = React.useRef(null);

    // React.useEffect(() => {
    //     // Чтобы не было белого фона при загрузке видео
    //     // setCanAddSrc(true);
    //     if (imageElement.current.clientHeight > 0) {
    //         console.log("Image height:", imageElement.current.clientHeight);
    //         console.log("Container height:", containerElement.current.clientHeight);
    //         const imageHeight = imageElement.current.clientHeight;
    //         containerElement.current.style.height = `${imageHeight+ 48}px`;
    //         imageElement.current.style.height = `${imageHeight}px`;
    //         console.log("New image height:", imageElement.current.clientHeight);
    //         console.log("New container height:", containerElement.current.clientHeight);
    //     }
    // }, []);

    return (
        <div className="case-hero-image__wrapper">
            <div
                ref={containerElement}
                className="case-hero-image">
                <div className="case-hero-image__angle case-hero-image__angle__top-left">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.1366 9.775V0H11.6149V9.775H34V11.2625H17.6149C14.3012 11.2625 11.6149 13.9488 11.6149 17.2625V34H10.1366V11.2625H0V9.775H10.1366Z"
                            fill="white"/>
                    </svg>
                </div>
                <div className="case-hero-image__angle case-hero-image__angle__top-right">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M23.8634 9.775V0H22.3851V9.775H0V11.2625H16.3851C19.6988 11.2625 22.3851 13.9488 22.3851 17.2625V34H23.8634V11.2625H34V9.775H23.8634Z"
                            fill="white"/>
                    </svg>
                </div>
                <img
                    src={image}
                    alt={imageALT}
                    ref={imageElement}/>
            </div>
            <div className="case-hero-image__footer">
                <div className="case-hero-image__angle case-hero-image__angle__bottom-left">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M10.1366 24.225V34H11.6149V24.225H34V22.7375H17.6149C14.3012 22.7375 11.6149 20.0512 11.6149 16.7375V0H10.1366V22.7375H0V24.225H10.1366Z"
                            fill="white"/>
                    </svg>
                </div>
                <div className="case-hero-image__angle case-hero-image__angle__bottom-right">
                    <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M23.8634 24.225V34H22.3851V24.225H0V22.7375H16.3851C19.6988 22.7375 22.3851 20.0512 22.3851 16.7375V0H23.8634V22.7375H34V24.225H23.8634Z"
                            fill="white"/>
                    </svg>
                </div>
            </div>
        </div>
    )
}

export default CaseHeroImage;
