import * as React from "react";

const Annotation = ({ text }) => {

    return (
        <span className="page-header__annotation">{text}</span>
    )
}
const Title = ({ text }) => {

    return (
        <h1 className="page-header__title">{text}</h1>
    )
}

const SubTitle = ({ text }) => {

    return (
        <h2 className="page-header__subtitle">{text}</h2>
    )
}

const PageHeader = ({ titleText, subTitleText, annotationText }) => {

    return (
        <div className="page-header">
            {annotationText && <Annotation text={annotationText} />}
            {titleText && <Title text={titleText} />}
            {subTitleText && <SubTitle text={subTitleText} />}
        </div>
    )
}

export default PageHeader;
