import * as React from "react";

const CaseTestimonials = ({ testimonials=[] }) => {

    return (
        <div className="case-testimonials">
        {testimonials.map((testimonial, index) => {
                return (
                    <div className="case-testimonials__testimonial" key={index}>
                        <svg width="62" height="56" viewBox="0 0 62 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M45.8805 26.8516C34.7096 25.8 29.3979 18.1809 27.2233 13.7145L22.3826 0L20.5208 0.654915L24.3338 11.4472C24.9314 14.0208 25.5496 18.8653 22.9933 22.4775C21.286 24.8924 18.417 26.3609 14.4644 26.8405H0V28.8016H16.2796C19.5561 29.1426 22.732 30.1232 25.624 31.6869C29.8373 34.0003 33.0415 37.4871 35.1397 42.0346L40.0028 55.8155L40.0642 56L41.926 55.3469L37.817 43.6913C36.8079 39.6326 37.184 36.3119 38.9341 33.8085C41.4606 30.1907 46.2305 29.1318 48.8575 28.8274H62V26.8516H45.8805Z"
                                fill="white"/>
                        </svg>
                        <h3 className="case-testimonials__testimonial-text">
                            {testimonial}
                        </h3>
                    </div>
                )
        })}
        </div>
    )
}

export default CaseTestimonials;
